import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import VuexPersistence from "vuex-persist";

import AccountModule from "@/store/modules/AccountModule";
import AuthModule from "@/store/modules/AuthModule";
import GoHighLevelModule from "@/store/modules/GoHighLevelModule";

config.rawError = true;

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

const store = createStore({
  modules: {
    AccountModule,
    AuthModule,
    GoHighLevelModule
  },
  plugins: [vuexLocal.plugin]
});

export default store;
