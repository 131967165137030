import { createApp } from 'vue'
import AdminConsole from './App.vue'

import router from "./router"
import store from "./store"

import 'bootstrap/dist/js/bootstrap.min.js'
import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import Column from 'primevue/column'
import Checkbox from 'primevue/checkbox'
import ColumnGroup from 'primevue/columngroup'
import DataTable from 'primevue/datatable'
import Dropdown from 'primevue/dropdown'
import InputText from "primevue/inputtext"
import InputSwitch from 'primevue/inputswitch'

//imports for app initialization
import ApiService from '@/core/services/ApiService'

const console = createApp(AdminConsole)
console.use(store)
console.use(router)

console.use(PrimeVue)
console.component('DataTable', DataTable)
console.component('Dropdown', Dropdown)
console.component('Column', Column)
console.component('Checkbox', Checkbox)
console.component('Button', Button)
console.component('ColumnGroup', ColumnGroup)
console.component('InputText', InputText)
console.component('InputSwitch', InputSwitch)

ApiService.init(console);

console.mount('#app')
