import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators"
import { ActiveAccount } from "@/core/interfaces/activeAccount"
import AccountService from "@/core/services/AccountService"
import store from "@/store"
import _ from "lodash"

export interface AccountStoreInfo {
  activeAccount: ActiveAccount;
}

@Module
export default class AccountModule extends VuexModule {
  errors = [];
  activeAccount = {} as ActiveAccount;
  allAccounts = {};

  /**
   * Get current activeAccount object
   * @returns activeAccount
   */
  get activeAccountInfo(): ActiveAccount {
    return {
      id: this.activeAccount.id,
      details: this.activeAccount.details,
      relationships: this.activeAccount.relationships,
      meta: this.activeAccount.meta
    };
  }

  get accounts() {
    return this.allAccounts;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_ACTIVE_ACCOUNT](response) {
    localStorage.setItem("loggedIn", "true")
    this.activeAccount = {
      id: response.data.id,
      details: response.data.attributes,
      relationships: response.data.relationships ? response.data.relationships : {},
      meta: response.meta ? response.meta : {}
    }
  }

  @Mutation
  [Mutations.UPDATE_ACTIVE_ACCOUNT](id) {
    this.activeAccount = {
      id: id,
      details: this.allAccounts[id],
      relationships: this.allAccounts[id].relationships ?? {},
      meta: this.allAccounts[id].meta ?? {}
    };
  }

  @Mutation
  [Mutations.UPDATE_ACTIVE_ACCOUNT_DETAIL](details: object) {
    for (const property in details) {
      this.activeAccount.details[property] = details[property]
    }
  }

  @Mutation
  [Mutations.UPDATE_ACTIVE_ACCOUNT_RELATIONSHIP](data) {
    if (this.activeAccount.relationships[data.name]) {

      // remove match, push new info
      this.activeAccount.relationships[data.name] = _.filter(this.activeAccount.relationships[data.name], (o) => {
        return o.id != data.data.id
      })

      this.activeAccount.relationships[data.name].push(data.data)

    } else {
      this.activeAccount.relationships[data.name] = []
      this.activeAccount.relationships[data.name].push(data.data)
    }

  }

  @Mutation
  [Mutations.REMOVE_ACTIVE_ACCOUNT_RELATIONSHIP](data) {
    if (this.activeAccount.relationships[data.name]) {

      // remove match
      this.activeAccount.relationships[data.name] = _.filter(this.activeAccount.relationships[data.name], (o) => {
        return o.id != data.data.id
      })

    }

  }

  @Action
  async [Actions.UPDATE_ACTIVE_ACCOUNT_INFO](id) {
    const account = await (new AccountService()).getAccountInfo(id)
    localStorage.setItem('accountInfo', JSON.stringify(account))
    localStorage.setItem('activeAccountId', account.client_id)
    store.commit(Mutations.SET_ACTIVE_ACCOUNT, account)
  }
}
