import store from "@/store"
import { Actions } from "@/store/enums/StoreEnums"
import ApiService from "@/core/services/ApiService"


/**
 * Check token expiration everything 30 minutes
 */
export const checkTokenExpiration = (): void => {
  const tokenExpiration = 240 // 4 hours = 240 minutes
  const intervalMs = 30 // check every 30 minutes
  let intervalThreshold = 0;

  const timer = setInterval(async () => {
    if (intervalThreshold < tokenExpiration) {
      intervalThreshold += intervalMs

      try {
        await ApiService.get("/auth-check");
      } catch (e) {
        await store.dispatch(Actions.LOGOUT)
      }

    } else {
      clearInterval(timer)
      await store.dispatch(Actions.LOGOUT)
    }

  }, (intervalMs * 60 * 10000)); // 60000 when testing
};
