import ApiService from "@/core/services/ApiService";

export default class AccountService {
  async getAccountInfo(accountNum) {
    ApiService.setHeader()

    let info;
    try {
      info = await ApiService.get("/accounts/" + accountNum)
    } catch (e) {
      console.error("Problem fetching account info", e)
      return [];
    }

    if (info.data) {
      return info.data
    } else {
      return []
    }
  }

  static async updateAccount(accountNum, data) {
    ApiService.setHeader()

    let resp;
    try {
      resp = await ApiService.put("/accounts/" + accountNum, { data: data })
    } catch (e) {
      console.error("Problem updating account", e)
      return false;
    }

    if (resp) {
      return resp
    }

    return false
  }

  static async getAccountUserConfigs(accountNum) {
    ApiService.setHeader()

    let info
    try {
      info = await ApiService.get("/accounts/" + accountNum + "/user-configs")
    } catch (e) {
      console.error("Problem fetching account UserConfigs", e)
    }

    return info.data ? info.data.data : []
  }
}
