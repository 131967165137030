/* eslint-disable */
import { CognitoAuth, StorageHelper } from 'amazon-cognito-auth-js'
import router from '../../router/index'
import axios from 'axios'
import store from "@/store"
import { Mutations, Actions } from "@/store/enums/StoreEnums"
import AccountService from "@/core/services/AccountService"
import ApiService from "@/core/services/ApiService"

const CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID
const APP_DOMAIN = process.env.VUE_APP_COGNITO_APP_DOMAIN
const REDIRECT_URI = process.env.VUE_APP_COGNITO_REDIRECT_URI
const USERPOOL_ID = process.env.VUE_APP_COGNITO_USERPOOL_ID
const REDIRECT_URI_SIGNOUT = process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT
const APP_URL = process.env.VUE_APP_APP_URL

var authData = {
  ClientId: CLIENT_ID,
  AppWebDomain: APP_DOMAIN,
  TokenScopesArray: ['openid', 'email'],
  RedirectUriSignIn: REDIRECT_URI,
  RedirectUriSignOut: REDIRECT_URI_SIGNOUT,
  UserPoolId: USERPOOL_ID,
}

var auth = new CognitoAuth(authData)
auth.userhandler = {
  onSuccess: async function (result) {
    // @TODO this gets called twice on login

    getUserInfo().then(response => {
      store.commit(Mutations.SET_AUTH, response)
      getMeInfo()
    })
  },
  onFailure: function (err) {
    // router.push({ path: '/error', query: { message: 'Login failed due to ' + err } })
    console.log("On Failure err", err)
    store.commit(Mutations.PURGE_AUTH)
    router.push({ name: '404' })
  }
}

function getUserInfoStorageKey() {
  var keyPrefix = 'CognitoIdentityServiceProvider.' + auth.getClientId()
  var tokenUserName = auth.signInUserSession.getAccessToken().getUsername()
  var userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo'
  return userInfoKey
}

function getUserInfo() {
  var jwtToken = auth.getSignInUserSession().getAccessToken().jwtToken
  const USERINFO_URL = 'https://' + auth.getAppWebDomain() + '/oauth2/userInfo'
  var requestData = {
    headers: {
      'Authorization': 'Bearer ' + jwtToken
    }
  }
  return axios.get(USERINFO_URL, requestData).then(response => {
    return response.data
  })
}

function getToken() {
  return auth.getSignInUserSession().getIdToken().jwtToken
}

function login() {
  localStorage.setItem('loggedIn', "true")
  auth.getSession()
}

function logout() {
  if (auth.isUserSignedIn()) {
    var userInfoKey = getUserInfoStorageKey()
    storage.removeItem(userInfoKey)
    auth.signOut()
  }
}

async function getAccountInformation() {
  if (store.getters.entryInfoAccountId > 0) {
    const account = await (new AccountService()).getAccountInfo(store.getters.entryInfoAccountId)
    localStorage.setItem('accountInfo', JSON.stringify(account))
    localStorage.setItem('activeAccountId', account.client_id)
    store.commit(Mutations.SET_ACTIVE_ACCOUNT, account)
  } else {
    router.push({ name: "account-select" })
  }
}

async function getMeInfo() {
  ApiService.setHeader()

  let me

  try {
    me = await ApiService.get('/me')
  } catch (e) {
    console.error("Problem fetching me response", e)
    return []
  }
  store.commit(Mutations.SET_USER, me.data)
  getAccountInformation()
}

var storageHelper = new StorageHelper()
var storage = storageHelper.getStorage()

export default auth
