
import { defineComponent, onMounted } from "vue"
import { useRouter, useRoute } from "vue-router"
import { checkTokenExpiration } from "@/core/helpers/tokenExpiration"
import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"

export default defineComponent({
  name: "admin",
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    onMounted(async () => {
      await router.isReady()
      checkTokenExpiration()
      if (route.params.id) {
        await store.dispatch(Actions.UPDATE_ACTIVE_ACCOUNT_INFO, route.params.id)
      }
    })
  }
});
