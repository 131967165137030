const ID_TOKEN_KEY = "id_token" as string;
const USER_KEY = "user" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

/**
 * @description save user meta data into localStorage
 * @param user: json object (string)
 */
export const saveUser = (user: string): void => {
  window.localStorage.setItem(USER_KEY, user);
};

/**
 * @description remove token form localStorage
 */
export const destroyUser = (): void => {
  window.localStorage.removeItem(USER_KEY);
};

export default { getToken, saveToken, destroyToken, saveUser, destroyUser };
