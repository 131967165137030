import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store"

export interface GHLIntegration {
  client_id: string,
  client_secret: string,
  accountNum: number,
  guid: string,
  redirect_uri: string
}

@Module
export default class GoHighLevelModule extends VuexModule {
  ghlIntegration = {} as GHLIntegration;

  /**
   * Get current activeAccount object
   * @returns ghlIntegration
   */
  get currentGHLIntegration(): GHLIntegration {
    return this.ghlIntegration;
  }

  @Mutation
  [Mutations.SET_GHL_INTEGRATION_PROPERTY](prop, value) {
    this.ghlIntegration[prop] = value
  }

  @Mutation
  [Mutations.SET_GHL_INTEGRATION](obj) {
    this.ghlIntegration = obj
  }

}
